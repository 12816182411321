import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FabbricaHttpTimeoutService {
  private static readonly DEFAULT_TIMEOUT = 10000;
  private static readonly specificTimeouts = [
    { url: '/smr-excel-report', timeout: 90000 },
    { url: '/birth-certificate/excel-report', timeout: 90000 },
    { url: '/birth-certificate/pdf-export', timeout: 240000 },
    { url: '/reports', timeout: 90000 }
  ];
  constructor() {}

  getTimeout(urlRequested: string): number {
    const specificTimeout = FabbricaHttpTimeoutService.specificTimeouts.find(
      ob => urlRequested.includes(ob.url)
    );
    return specificTimeout
      ? specificTimeout.timeout
      : FabbricaHttpTimeoutService.DEFAULT_TIMEOUT;
  }
}
